import React from 'react'
import Links from "../../Links";
import WasKannDarthelfer from "../../WasKannDarthelfer";
import InteresseAnDarthelfer from "../../InteresseAnDarthelfer";
import {Systemvoraussetzungen} from "../Systemvoraussetzungen";
import StartseiteLinks from "./StartseiteLinks";
import DHMobileDiv from "../../general/DHMobileDiv";
import StartMobilTournament from "../../StartMobilTournaments";
import {StartZertifikat} from "../StartZertifikat";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import {Button} from "primereact/button";

class Start extends React.Component {

    render() {
        return <div>
            <div style={{marginTop:10, marginBottom: 10}}>
                <NavLink to={NAVIGATION_ITEM.PUBLIC_TOURNAMENTS.route} style={{textDecoration: "none"}}>
                    <Button label="Hier geht es direkt zur Eventübersicht..." style={{width: "100%"}}/>
                </NavLink>
            </div>
            <DHMobileDiv comp={<StartMobilTournament/>}/>
            <div className="grid" style={{marginLeft: 10, marginRight: 10, marginTop: 10}}>
                <WasKannDarthelfer/>
                <StartZertifikat/>
                <StartseiteLinks cols={3} isOnlineDashboard={true}/>
                <InteresseAnDarthelfer/>
                <Systemvoraussetzungen/>
            </div>
            <div className="grid" style={{marginLeft: 10, marginRight: 10, marginTop: 10}}>
                <Links/>
            </div>
        </div>
    }
}

Start.propTypes = {};

export default Start
