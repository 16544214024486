import React, {useEffect, useState} from 'react'
import {NAVIGATION_ITEM} from "../constants/navigationItems";
import {NavLink} from "react-router-dom";
import {getFromLocalStorageWithDefault, KEY_LAST_EVENTS, saveToLocalStorage} from "../util/localStorageZugriff";
import {PrimeIcons} from "primereact/api";

export default function StartMobilTournament() {

    const [showDetails, setShowDetails] = useState(false);
    const [lastEvents, setLastEvents] = useState([]);

    useEffect(() => {
        loadFromLocalStorage();
    }, []);

    const loadFromLocalStorage = () => {
        setLastEvents(getFromLocalStorageWithDefault(KEY_LAST_EVENTS, []));
    }

    const getDetailsArea  = () => {
        if( !showDetails) {
            return "";
        }
        let rows = [];

        for (const lastEvent of lastEvents) {
            rows.push(<div key={"key_last_event" + lastEvent.id + "_type_" + lastEvent.type } style={{marginTop: 10}}>
                <NavLink to={NAVIGATION_ITEM.PUBLIC_TOURNAMENT.route + "/" + lastEvent.id} style={{textDecoration: "none"}}>
                    <span style={{color: "black"}}>{lastEvent.name}</span>
                </NavLink>
            </div>);
        }
        return <div style={{marginTop:20}}>{rows}</div>;
    }

    const deleteHistory = () => {
        saveToLocalStorage(KEY_LAST_EVENTS, []);
        loadFromLocalStorage();
    }

    return <div style={{padding: 20, paddingBottom: 10}}>
        <div className="kachelStartseite" style={{width: "100%", height: "100%"}}>
            {lastEvents.length > 0 && <div style={{marginTop: 20, cursor:"pointer"}}>
                <NavLink to={NAVIGATION_ITEM.PUBLIC_TOURNAMENT.route + "/" + lastEvents[0].id} style={{textDecoration: "none"}}>
                    <span style={{color:"black"}}>Zuletzt besucht:<br/>{lastEvents[0].name}</span>
                </NavLink>
            </div>
            }
            {lastEvents.length > 1 && !showDetails &&
                <div style={{marginTop:10, marginBottom: 10, cursor:"pointer"}} onClick={() => setShowDetails(true)}>
                    <i className={PrimeIcons.ANGLE_DOWN} style={{marginRight:5}}/>
                    mehr anzeigen...
                </div>}
            {lastEvents.length > 1 && showDetails && <div style={{marginTop: 10, marginBottom: 10, cursor:"pointer"}} onClick={() => setShowDetails(false)}>
                <i className={PrimeIcons.ANGLE_UP} style={{marginRight: 5}}/>
                weniger anzeigen...
            </div>}
            {getDetailsArea()}
            {lastEvents.length > 0 &&
                <div style={{marginTop:30, marginBottom: 10, cursor:"pointer"}} onClick={() => deleteHistory()}>
                    <hr/>
                    <i className={PrimeIcons.TRASH} style={{marginRight:5}}/>
                    Verlauf löschen
                </div>}
        </div>
    </div>
}
