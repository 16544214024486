import React, {useEffect, useState} from 'react'
import DHDialog from "../../general/DHDialog";
import DHButtonSave from "../../general/DHButtonSave";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import DHTextField from "../../general/DHTextField";
import DHSwitch from "../../general/DHSwitch";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import DHButtonNew from "../../general/DHButtonNew";
import SelectZeitraumComp from "./SelectZeitraumComp";
import SelectRegionComp from "./SelectRegionComp";
import DHSelect from "../../general/DHSelect";
import {LIGAMODUS} from "../../general/ligaModus";


export default function LigaVerwaltenDialog({eventOperatorId, selectedLiga, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [working, setWorking] = useState(false);

    const [id, setId] = useState(undefined);
    const [regionId, setRegionId] = useState("");
    const [zeitraumId, setZeitraumId] = useState("");
    const [name, setName] = useState("");
    const [modusId, setModusId] = useState("");
    const [teamGame, setTeamGame] = useState(false);
    const [aktiv, setAktiv] = useState(true);

    useEffect(() => {
        if( selectedLiga ) {
            setId(selectedLiga.id);
            setName(selectedLiga.name);
            setRegionId(selectedLiga.regionId);
            setZeitraumId(selectedLiga.zeitraumId);
            setModusId(selectedLiga.modusId);
            setTeamGame(selectedLiga.teamGameBeiUnentschieden);
            setAktiv(selectedLiga.aktiv);
        }
    }, [selectedLiga]);

    const save = () => {
        setWorking(true);
        let data = {id: id, name: name, regionId: regionId, zeitraumId: zeitraumId, modusId: modusId, teamGameBeiUnentschieden: teamGame, aktiv: aktiv}
        let url = ConfigUtil.getConfig().resourceUrls.league + "/liga/save/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            data,
            json => {
                setId(json.id);
                setInfoMessage("Speichern erfolgreich...");
                onRefresh();
                setWorking(false);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setWorking(false);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setWorking(false);
            });
    }

    const initDialog = () => {
        setId(undefined);
        setName("");
        setAktiv(true);
    }

    const isDisabled = () => {
        return name === "" || !zeitraumId || !regionId;
    }

    const getModusModel = () => {
        let rows = [];
        for (const tmp of LIGAMODUS) {
            rows.push({id: tmp.code, value: tmp.name});
        }
        return rows;
    }
    const getButtons = () => {
        return [<DHButtonSave onClick={() => save()} working={working} disabled={isDisabled()}/>,
            <DHButtonNew onClick={() => initDialog()}/>];
    }


    const getDataArea = () => {
        return <div style={{padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <div style={{marginTop: 10}}>
                <DHTextField id="id-name" label="Liganame" width={500} required={true} value={name} onChange={(text) => setName(text)}/>
            </div>
            <div style={{marginTop: 10}}>
                <SelectZeitraumComp eventOperatorId={eventOperatorId}
                                    zeitraumId={zeitraumId} refreshCounter={0}
                                    onChange={(id) => setZeitraumId(id)} onError={(message) => setErrorMessage(message)}/>
            </div>
            <div style={{marginTop: 10}}>
                <SelectRegionComp eventOperatorId={eventOperatorId}
                                  refreshCounter={0} regionId={regionId} onChange={(id) => setRegionId(id)} onError={(message) => setErrorMessage(message)}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHSelect id="id-liga-modus" label="Modus" width={300} onChange={(id) => setModusId(id)} value={modusId} model={getModusModel()}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHSwitch id="id-aktiv" label="Team-Game bei unentschieden" value={teamGame} onChange={(value) => setTeamGame(value)} required={true}/>
            </div>
            <div style={{marginTop: 10}}>
                <DHSwitch id="id-aktiv" label="Aktiv" value={aktiv} onChange={(value) => setAktiv(value)} required={true}/>
            </div>
        </div>
    }

    return <DHDialog onClose={() => onClose()} title="Liga verwalten" show={true} showCloseButton={true}
                     buttons={getButtons()} comp={getDataArea()}/>
}
