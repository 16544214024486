import React, {useEffect, useState} from 'react'
import DHDialog from "../../general/DHDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {Button, TableContainer} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DHTextFieldSearch from "../../general/DHTextFieldSearch";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import DHSwitch from "../../general/DHSwitch";
import DoneIcon from "@mui/icons-material/Done";
import BlockIcon from "@mui/icons-material/Block";
import ConfirmDialog from "../../general/ConfirmDialog";

export default function LigaMannschaftZuordnunenDialog({eventOperatorId, liga, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [filter, setFilter] = useState("");
    const [filterAktive, setFilterAktive] = useState(false);
    const [mannschaften, setMannschaften] = useState([]);
    const [mannschaftenZugewiesen, setMannschaftenZugewiesen] = useState([]);

    const [selectedMannschaft, setSelectedMannschaft] = useState(undefined);
    const [showZuweisenDialog, setShowZuweisenDialog] = useState(undefined);
    const [showLoeschenDialog, setShowLoeschenDialog] = useState(undefined);

    const CELL_PADDING_VALUE = 10;

    useEffect(() => {
        ladeMannschaften();
        ladeMannschaftenZugeweisen();
    }, []);

    const ladeMannschaftenZugeweisen = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/liga/mannschaften/zugewiesen/" + liga.id;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setMannschaftenZugewiesen(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const ladeMannschaften = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/mannschaften/" + eventOperatorId;
        FetchUtil.fetchPost(url,
            {},
            json => {
                setMannschaften(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
            });
    }

    const zuweisungLoeschen = () => {
            let url = ConfigUtil.getConfig().resourceUrls.league + "/liga/mannschaft/zuweisung/loeschen/" + liga.id + "/" + selectedMannschaft.id;
            FetchUtil.fetchPost(url,
                {},
                () => {
                    setInfoMessage("Zuordnung wurde gelöscht.");
                    setShowLoeschenDialog(false);
                    setSelectedMannschaft(undefined);
                    ladeMannschaftenZugeweisen();
                    onRefresh();
                },
                responseNotOk => {
                    setErrorMessage("Fehler: " + responseNotOk.message);
                    setShowLoeschenDialog(false);
                    setSelectedMannschaft(undefined);
                },
                error => {
                    setErrorMessage("Fehler: " + error.message)
                    setShowLoeschenDialog(false);
                    setSelectedMannschaft(undefined);
                });
    }

    const zuweisen = () => {
        let url = ConfigUtil.getConfig().resourceUrls.league + "/liga/mannschaft/zuweisen/" + liga.id + "/" + selectedMannschaft.id;
        FetchUtil.fetchPost(url,
            {},
            () => {
                setInfoMessage("Mannschaft wurde zugewiesen");
                setShowZuweisenDialog(false);
                setSelectedMannschaft(undefined);
                ladeMannschaftenZugeweisen();
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setShowZuweisenDialog(false);
                setSelectedMannschaft(undefined);
            },
            error => {
                setErrorMessage("Fehler: " + error.message)
                setShowZuweisenDialog(false);
                setSelectedMannschaft(undefined);
            });
    }

    const getMannschaftenZugewiesenTableRows = () => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        let filteredMannschaften;
        if( filter.length > 0 ) {
            filteredMannschaften = mannschaften.filter(tmp => tmp.bezeichnung.toUpperCase().includes(filter.toUpperCase()));
        } else {
            filteredMannschaften = mannschaften;
        }
        if( filterAktive ) {
            filteredMannschaften = filteredMannschaften.filter(tmp => tmp.aktiv);
        }
        let ids = [];
        for (const zuordnung of mannschaftenZugewiesen) {
            ids.push(zuordnung.mannschaftId);
        }
        filteredMannschaften = filteredMannschaften.filter(t => ids.includes(t.id));
        filteredMannschaften.forEach(mannschaft => {
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={mannschaft.id}>
                <TableCell style={style}>
                    <Button onClick={() => {
                        setSelectedMannschaft(mannschaft);
                        setShowLoeschenDialog(true);
                    }
                    }>Löschen</Button>
                </TableCell>
                <TableCell style={style}>{mannschaft.bezeichnung}</TableCell>
                <TableCell style={style}>{mannschaft.aktiv ? <DoneIcon/> : <BlockIcon/>}</TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    const getMannschaftenTableRows = () => {
        let returnRows = [];
        let background = false;
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 18, padding:CELL_PADDING_VALUE};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 18, padding:CELL_PADDING_VALUE};

        let filteredMannschaften;
        if( filter.length > 0 ) {
            filteredMannschaften = mannschaften.filter(tmp => tmp.bezeichnung.toUpperCase().includes(filter.toUpperCase()));
        } else {
            filteredMannschaften = mannschaften;
        }
        if( filterAktive ) {
            filteredMannschaften = filteredMannschaften.filter(tmp => tmp.aktiv);
        }
        let ids = [];
        for (const zuordnung of mannschaftenZugewiesen) {
            ids.push(zuordnung.mannschaftId);
        }
        filteredMannschaften = filteredMannschaften.filter(t => !ids.includes(t.id));

        filteredMannschaften.forEach(mannschaft => {
            let style = background ? styleWithBackground : styleWithoutBackground;
            returnRows.push( <TableRow key={mannschaft.id}>
                <TableCell style={style}>
                    <Button onClick={() => {
                        setSelectedMannschaft(mannschaft);
                        setShowZuweisenDialog(true);
                    }
                    }>Zuweisen</Button>
                </TableCell>
                <TableCell style={style}>{mannschaft.bezeichnung}</TableCell>
                <TableCell style={style}>{mannschaft.aktiv ? <DoneIcon/> : <BlockIcon/>}</TableCell>
            </TableRow>)
            background = !background;
        });
        return returnRows;
    }

    const getDataArea = () => {
        return <div style={{padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)}
                                onClearErrorMessage={() => setErrorMessage(undefined)}/>
            <div style={{fontSize: 16}}>Gewählte Liga: {liga.name}</div>
            <div style={{paddingTop: 20, display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
                <DHTextFieldSearch id="id-filter" onChange={(text) => setFilter(text)} value={filter} width={100} label="Filter"/>
                <span style={{marginTop: 10, marginLeft: 10}}>
                    <DHSwitch id="id-filter-nur-aktive" value={filterAktive} onChange={() => setFilterAktive(!filterAktive)} label="nur aktive"/>
                </span>
            </div>
            <div style={{fontWeight: "bold", marginTop: 20}}>Zugewiesene Mannschaften</div>
            <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop: 20}}>
                <Table stickyHeader style={{backgroundColor: "#022836", color: "white", padding: 0}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 100,}}>#</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Verein</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, width: 40, padding: CELL_PADDING_VALUE}}>Aktiv</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getMannschaftenZugewiesenTableRows()}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{fontWeight: "bold", marginTop: 20}}>Nicht zugewiesene Mannschaften</div>
            <TableContainer style={{maxHeight: "100%", paddingBottom: 0, marginTop: 20}}>
                <Table stickyHeader style={{backgroundColor: "#022836", color: "white", padding: 0}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE, width: 100,}}>#</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, padding: CELL_PADDING_VALUE}}>Verein</TableCell>
                            <TableCell style={{backgroundColor: "#ac1c27", color: "white", fontSize: 14, width: 40, padding: CELL_PADDING_VALUE}}>Aktiv</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getMannschaftenTableRows()}
                    </TableBody>
                </Table>
            </TableContainer>
            {showZuweisenDialog && <ConfirmDialog callBackOnJa={() => zuweisen()} visible={true} callBackOnNein={() => {
                setShowZuweisenDialog(false);
                setSelectedMannschaft(undefined);
            }} header="Zuweisen..." textOben={"Möchtest du die gewählte Mannschaft '" + selectedMannschaft.bezeichnung + "' wirklich der Liga '" + liga.name + "' zuweisen?"}/>}
            {showLoeschenDialog && <ConfirmDialog callBackOnJa={() => zuweisungLoeschen()} visible={true} callBackOnNein={() => {
                setShowLoeschenDialog(false);
                setSelectedMannschaft(undefined);
            }} header="Löschen..." textOben={"Möchtest du die Zuordnung der gewählten Mannschaft '" + selectedMannschaft.bezeichnung + "' zur  Liga '" + liga.name + "' wirklich löschen?"}/>}
        </div>
    }
    return <DHDialog onClose={() => onClose()} title="Mannschaft - Zuordnung" showCloseButton={true} comp={<>{getDataArea()}</>}/>
}
